<template>
   <div class="header">
     <div v-if="back" @click="$router.go(-1)">
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
         <path d="M1.5 8.00248H14.5M1.5 8.00248L7.26 1.5M1.5 8.00248L7.26 14.5" stroke="#292B32" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
     </div>
     <span>{{title}}</span>
   </div>
</template>

<script>
export default {
name: "BaseHeader",
  props: {
    title: {
      type: String,
      default: ''
    },
    back: {
      type: Boolean
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--txt, #2C2C2C);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 0 20px 0;

}
</style>