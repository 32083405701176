<template>
  <div class="error-lds">
    <base-header title="Ошибки LDS" back ></base-header>
    <div  class="error-lds__content">
      <div  v-if="itemList.length" class="error-lds__spoiler spoiler">
        <el-collapse>
          <el-collapse-item v-for="(item, index) in itemList" :key='index' :name="index">
            <template slot="title">
               <div class="spoiler__title">
                 <span>{{index+1}}. Событие</span>
                 <span>{{formatDate(item.createdAt)}}</span>
               </div>
            </template>
              <div class="spoiler__content">
                <div class="spoiler__content-item">
                  <span class="spoiler__content-item--label">мастер</span>
                  <span class="spoiler__content-item--value">{{item.master}}</span>
                </div>
                <div class="spoiler__content-item">
                  <span class="spoiler__content-item--label">слэйв</span>
                  <span class="spoiler__content-item--value">{{item.slave}}</span>
                </div>
                <div class="spoiler__content-item">
                  <span class="spoiler__content-item--label">Код ошибки</span>
                  <span class="spoiler__content-item--value">{{item.errorCode}}</span>
                </div>
                <div class="spoiler__content-item">
                  <span class="spoiler__content-item--label">Текст ошибки</span>
                  <span class="spoiler__content-item--value">{{item.errorMessage}}</span>
                </div>
              </div>
          </el-collapse-item>

        </el-collapse>
      </div>
      <div v-else > Данных нет</div>
      <choose-machine class="error-lds--footer" :showTwoSelect="false"></choose-machine>
    </div>


  </div>
</template>

<script>
import BaseHeader from "@/components/common/BaseHeader";
import ChooseMachine from "@/components/chooseMachine";
import axios from "axios";
import eventBus from "@/eventBus";
export default {
name: "errorsLDS",
  components: {ChooseMachine, BaseHeader},
  data() {
    return {
      itemList: []
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU', { month: 'short', day: 'numeric' });
    },
   async getList(masterId) {
      if (masterId) {
        try {
          const response = await axios.get(`/diagnostic/journal?masterId=${masterId}`);
          this.itemList = response.data
        } catch (error) {
          this.itemList = []
          console.error(error);
        }
      }

      }
    },
  mounted() {
  this.getList()
    eventBus.$on('chooseMachine', (item) => {
      this.getList(item.id)
    })
  },
  beforeDestroy() {
    eventBus.$off('chooseMachine')
  },
}
</script>

<style>
.el-collapse-item__header {
  background: #f9f9f9;
}
.el-collapse-item__content {
  background: #f9f9f9;
}
</style>

<style lang="scss" scoped>
.error-lds {
  height: 85vh;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
  }
  &__spoiler {
    padding: 16px 25px;
    background: #f9f9f9;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: scroll;
  }
  .spoiler {
    &__title {
      display: flex;
      gap: 25px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &-item {
        display: flex;
        gap: 16px;
        &--label {
          font-weight: 400;
          font-size: 14px;
          line-height: 143%;
          color: #707990;
        }
        &--value {
          font-weight: 400;
          font-size: 14px;
          line-height: 143%;
          color: #292b32;
        }
      }
    }
  }
  &--footer {
    border-top: 1px solid #d0d7e8;
    padding-top: 16px;
  }
}
</style>