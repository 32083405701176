<template>
 <div class="autotest">
   <base-header title="Автотест LDS"  back></base-header>
   <div class="autotest__title">
     Перебор всех клапанов и насосов по 3 секунды
   </div>
   <div class="autotest__select">
    <choose-machine :show-two-select="false"></choose-machine>
   </div>
   <el-button :disabled="!!!idMachine" @click="getfullDiagnostic" type="primary">Проверить все компоненты</el-button>
 </div>
</template>

<script>
import BaseHeader from "@/components/common/BaseHeader";
import ChooseMachine from "@/components/chooseMachine";
import eventBus from "@/eventBus";
import axios from "axios";
export default {
name: "autoTestLDS",
  components: {ChooseMachine, BaseHeader},
  data() {
    return {
      options1: [],
      idMachine: null,
    }
  },
  methods: {

    async getfullDiagnostic(masterId) {
      if (this.idMachine) {
        try {
          const response = await axios.get(`/diagnostic/full?masterId=${masterId}`);
          this.options1 = response.data.slaves
        } catch (error) {
          console.error(error);
        }
      }

    },
  },
  mounted() {
    eventBus.$on('chooseMachine', (item) => {
      this.idMachine = item.id
    })
  },
  beforeDestroy() {
    eventBus.$off('chooseMachine')
  },
}
</script>

<style scoped>
.autotest {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>