<template>
  <div class="dozation">
    <div>
      <base-header title="Ручная дозация" back ></base-header>
      <div class="form">
        <div class="form-item">
          <div class="form-item__title">
            1. Промывка 1
          </div>
          <div class="form-item__content">
            <div class="form-item__content--single">
              <label>Промывка</label>
              <el-input v-model="flush1Duration" type="number" placeholder="Введите секунды"></el-input>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="form-item__title">
            2. Химия
          </div>
          <div class="form-item__content">
            <div class="form-item__content--single">
              <label>Время работы</label>
              <el-input v-model="chemistryDuration" type="number" placeholder="Введите секунды"></el-input>
            </div>
            <div class="form-item__content--single">
              <label>Номер насоса</label>
              <el-select v-model="chemistry"  placeholder="Номер насоса">
                <el-option
                    v-for="(item, index) in options2"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="form-item__title">
            3. Промывка 2
          </div>
          <div class="form-item__content">
            <div class="form-item__content--single">
              <label>Промывка</label>
              <el-input v-model="flush2Duration" type="number" placeholder="Введите секунды"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="active-panel">
        <el-button @click='getAnswer' class="button--width">Дозировать</el-button>
        <div class="text">
          <div :class="statusColor"></div>
          <span>Статус дозации</span>
        </div>
      </div>
    </div>
    <choose-machine></choose-machine>

  </div>

</template>

<script>
import axios from 'axios'
import BaseHeader from "@/components/common/BaseHeader";
import ChooseMachine from "@/components/chooseMachine";
import eventBus from "@/eventBus";

export default {
  name: 'FirstPage',
  components: {ChooseMachine, BaseHeader},
  data() {
    return {
      options2: [],
      id: '',
      targetMachine: '',
      chemistryDuration: '',
      flush2Duration: '',
      flush1Duration: '',
      chemistry: '',
      answer: {},
      statusColor: 'default-status',
    };
  },
  methods: {
    async getPump(masterId) {
      if (masterId) {
        try {
          const response = await axios.get(`/master/get_pumps?masterId=${masterId}`);
          this.options2 = response.data.pumps
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAnswer() {
      this.statusColor = 'status-yellow';

      let obj = {
        id: this.id,
        targetMachine: this.targetMachine,
        chemistryDuration: this.chemistryDuration,
        flush2Duration: this.flush2Duration,
        flush1Duration: this.flush1Duration,
        chemistry: this.chemistry,
      };

      try {
        const response = await axios.post("/master/enable", {...obj});
        response.status === 200 ? this.statusColor = 'status status-green' : this.statusColor = 'status status-red';
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.statusColor = 'status status-red';
        } else {
          this.statusColor = 'default-status';
        }
      }
    },
  },
  mounted() {
    eventBus.$on('chooseMachine', (item) => {
      this.id = item.id
      this.targetMachine = item.targetMachine
      this.getPump(item.id)
    })
  },
  beforeDestroy() {
    eventBus.$off('chooseMachine')
  },
};
</script>

<style>
.el-select>.el-input {
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.el-input {
  font-size: 16px;
}
.el-select-dropdown__item {
  font-size: 16px;
}
.wrapper-chooseMachine {
  border-top: 1px solid #D0D7E8;
  padding-top: 16px;
}
.default-status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #646464;
}
.status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  &-green {
    background: #30C06C;
  }
  &-red {
    background: #ff0915;
  }
  &-yellow {
    background: #ffda34;
  }
}
.dozation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &__title {
        color: var(--txt, #292B32);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px
      }
      &__content {
        display: flex;
        gap: 19px;
        &--single {
          display: flex;
          flex-direction: column;
          gap: 6px;
          max-width: 160px;
        }
       label {
         color: var(--main-gray, #707990);
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 18px;
       }
      }
    }
  }
  .active-panel {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    gap: 16px;
    .button {
      &--width {

        width: 100%;
      }
    }
    .text {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        color: #292B32;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

  }
}
</style>
