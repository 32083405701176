import MenuBase from "@/components/MenuBase";
import FirstPage from "@/components/FirstPage";
import infoSlave from "@/pages/infoSlave";
import autoTestLDS from "@/pages/autoTestLDS";
import errorsLDS from "@/pages/errorsLDS";
import MonitoringSystem from "@/pages/MonitoringSystem";

const routes = [
    {
        path: '/',
        component: MenuBase,
    },
    {
        path: '/hand-dozation',
        component: FirstPage,
    },
    {
        path: '/info-slave',
        component: infoSlave,
    },
    {
        path: '/auto-test-lds',
        component: autoTestLDS,
    },
    {
        path: '/monitoring-system',
        component: MonitoringSystem,
    },
    {
        path: '/errors-lds',
        component: errorsLDS,
    },
];

export default routes;