<template>
  <div v-loading="loading" class="wrapper-monitoringSlave">
    <div class="table" v-if="tableData.length" >
      <table>
        <thead>
        <tr>
          <th></th>
          <th>сеть</th>
          <th>в работе</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="master">
          <td>Мастер</td>
          <td>
            <span v-if="master.inNetwork" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
          <td>
            <span v-if="master.isBusy" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
        </tr>
        <tr v-for="(item, index) in tableData" :key="index">
          <td>Машинка {{index + 1}}</td>
          <td>
            <span v-if="item.inNetwork" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
          <td>
            <span v-if="item.washingState" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
        </tr>
        <tr>
          <td>Процессы слейвов</td>
          <td>
            <span v-if="processInfo.inWork" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
          <td>
            <span v-if="processInfo.inNetwork" class="circle green"></span>
            <span v-else class="circle red"></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      <choose-machine class="chooseMachine" :show-two-select="false"></choose-machine>
      <el-button :disabled="id === ''" class="footer__btn" type="primary" @click="getSlaveInfo">Проверить</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import eventBus from "@/eventBus";
import ChooseMachine from "@/components/chooseMachine";

export default {
name: "getMonitoringSystem",
  components: {ChooseMachine},
  data() {
    return {
      id: '',
      inNetwork: null,
      master: null,
      loading: false,
      tableData: {},
      processInfo: {},
    }
  },
  methods: {
    async getSlaveInfo() {
      this.loading = true
      try {
        const {data} = await axios.get(`/monitor/full-point?placeId=${this.id}`);
        this.tableData = data.slaveList
        this.master = data.master
        this.loading = false
        await this.getProcessSlaveInfo()
      } catch (error) {
        await this.getProcessSlaveInfo()
        this.loading = false
        console.error(error);
      }
    },
    async getProcessSlaveInfo() {
      this.loading = true
      try {
        const {data} = await axios.get(`/monitor/threads-status?placeId=${this.id}`);
        this.processInfo = data
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error);
      }
    },
  },
  mounted() {
    eventBus.$on('chooseMachine', (item) => {
      this.id = item.id
    })
  },
  beforeDestroy() {
    eventBus.$off('chooseMachine')
  },
}
</script>

<style lang="scss">
 .chooseMachine {
   .item {
     width: 100%;
   }
 }
</style>

<style lang="scss" scoped>

.wrapper-monitoringSlave {
  display: flex;
  flex-direction: column;
  height: 86vh;
  justify-content: space-between;
}
.table {
  overflow: scroll;
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #F9F9F9;
    border-radius: 5px;

    tr {
      border-bottom: 1px solid #e3e3e3;

      th, td {
        padding: 10px;
        text-align: left;
        &:nth-child(2), &:nth-child(3) {
          text-align: center; // Горизонтальное выравнивание
          vertical-align: middle; // Вертикальное выравнивание
        }
      }



      th {
        background-color: #ddd;
        color: #333;
      }
      .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: auto;
      }

      .green {
        background-color: green;
      }

      .red {
        background-color: red;
      }
    }
  }
}
.footer {
  border-top: 1px solid #d0d7e8;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__btn {
    width: 100%;
  }
}
</style>