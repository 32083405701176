<template>
  <div class="wrapper-chooseMachine">
    <div class="item">
      <label>Прачечная</label>
      <el-select v-model="id">
        <el-option
            v-for="(item, index) in masterOptions"
            :key="index"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="item" v-if="showTwoSelect">
      <label>Номер машинки</label>
      <el-select v-model="targetMachine">
        <el-option
            v-for="(item, index) in machineNumbers"
            :key="index"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import eventBus from "@/eventBus";

export default {
name: "chooseMachine",
  props: {
    showTwoSelect: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      masterOptions: [],
      machineNumbers: [],
      id: '',
      targetMachine: '',
    };
  },
  methods: {
    async getMasterOptions() {
      try {
        const response = await axios.get("/master/get_all");
        this.masterOptions = response.data.options
      } catch (error) {
        console.error(error);
      }
    },
    async getSlaveOptions(masterId) {
      if (masterId) {
        try {
          const response = await axios.get(`/slave/point_slaves?masterId=${masterId}`);
          this.machineNumbers = response.data.slaves
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  watch: {
    id: {
      handler(newValue) {
        let machineInfo = {id: this.id, targetMachine: this.targetMachine }
        this.getSlaveOptions(newValue);
        eventBus.$emit('chooseMachine', machineInfo)
      },
      immediate: true
    },
    targetMachine: {
      handler() {
        let machineInfo = {id: this.id, targetMachine: this.targetMachine }
        eventBus.$emit('chooseMachine', machineInfo)
      }
    }
  },
  created() {
    this.getMasterOptions();
  },
}
</script>

<style lang="scss" scoped>
.wrapper-chooseMachine {
  display: flex;
  gap: 17px;
  .item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    label {
      color: var(--main-gray, #707990);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>