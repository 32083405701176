import { render, staticRenderFns } from "./MenuBase.vue?vue&type=template&id=1340e39c&scoped=true"
import script from "./MenuBase.vue?vue&type=script&lang=js"
export * from "./MenuBase.vue?vue&type=script&lang=js"
import style0 from "./MenuBase.vue?vue&type=style&index=0&id=1340e39c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1340e39c",
  null
  
)

export default component.exports