<template>
  <div v-loading="loading" class="wrapper-infoSlave">
    <div class="table" v-if="tableData.washingData" >
      <table>
        <thead>
        <tr>
          <th></th>
          <th>значение</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in tableData.washingData" :key="index">
          <td>сигнал {{index + 1}}</td>
          <td>
            <div>
              <span v-if="item === '1'" class="circle green"></span>
              <span v-else-if="item === '0'" class="circle red"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Программа запущена</td>
          <td>
            <div>
              <span v-if="tableData.washingState" class="circle green"></span>
              <span v-else class="circle red"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            Сеть
          </td>
          <td>
            <div>
              <span v-if="inNetwork" class="circle green"></span>
              <span v-else class="circle red"></span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="controller" v-if="tableData.washingData">
      <div>Процесс на слейве запущен:  <span class="bold">{{formattedCreatedAt}}</span> </div>
      <el-button @click="getRestartInfo" type="text">Перезапустить процесс</el-button>
    </div>
     <div class="footer">
       <choose-machine></choose-machine>
       <el-button :disabled="targetMachine === ''" class="footer__btn" type="primary" @click="getSlaveInfo">Проверить</el-button>
     </div>
  </div>


</template>

<script>
import 'vue-json-pretty/lib/styles.css';
import ChooseMachine from "@/components/chooseMachine";
import eventBus from "@/eventBus";
import axios from "axios";
import moment from 'moment'


export default {
name: "getInfoSlave",
  components: {ChooseMachine},
  data() {
  return {
    id: '',
    targetMachine: '',
    inNetwork: null,
    loading: false,
    createdAt: '',
    tableData: {}
  }
  },
  methods: {
    async getSlaveInfo() {
      this.loading = true
        try {
          const {data} = await axios.get(`/slave/${this.targetMachine}`);
           this.tableData = data
           this.inNetwork = data.inNetwork
           this.createdAt = data.createdAt
          this.loading = false
        } catch (error) {
          this.loading = false
          console.error(error);
        }
    },
    async getRestartInfo() {
      try {
         await axios.get(`/diagnostic/restart-process`, {params: {placeId: this.targetMachine}});
      } catch (error) {
        console.error(error);
      }
    },
    },
  computed: {
    formattedCreatedAt() {
      moment.locale('ru');
      return moment(this.createdAt).format('HH:mm, D MMM');
    }
  },
  mounted() {
    eventBus.$on('chooseMachine', (item) => {
      this.id = item.id
      this.targetMachine = item.targetMachine
    })
  },
  beforeDestroy() {
    eventBus.$off('chooseMachine')
  },
}
</script>

<style lang="scss" scoped>
.wrapper-infoSlave {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
  justify-content: space-between;
}
.bold {
  font-weight: 600;
}
.table {
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #F9F9F9;
    border-radius: 5px;

    tr {
      border-bottom: 1px solid #e3e3e3;

      th, td {
        padding: 10px;
        text-align: left;
        &:nth-child(2), &:nth-child(3) {
          text-align: center; // Горизонтальное выравнивание
          vertical-align: middle; // Вертикальное выравнивание
        }
      }



      th {
        background-color: #ddd;
        color: #333;
      }
      .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: auto;
      }

      .green {
        background-color: green;
      }

      .red {
        background-color: red;
      }
    }
  }
}
.controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .footer {
   position: fixed;
   bottom: 0;
   left: 0;
   padding: 15px;
   border-top: 1px solid #d0d7e8;
   width: fit-content;
   display: flex;
   flex-direction: column;
   gap: 20px;
   &__btn {
     width: 100%;
   }
 }
</style>