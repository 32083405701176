<template>
  <div>
    <base-header title="Проверка слейва" back ></base-header>
    <get-info-slave></get-info-slave>
  </div>
</template>

<script>
import GetInfoSlave from "@/components/getInfoSlave";
import BaseHeader from "@/components/common/BaseHeader";
export default {
name: "infoSlave",
  components: {BaseHeader, GetInfoSlave}
}
</script>

<style scoped>

</style>