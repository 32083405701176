<template>
 <div class="menu">
   <base-header title="Система дозации"></base-header>

    <div class="menu__items">
<!--      <div class="menu__item">-->
<!--        Мониторинг систем-->
<!--      </div>-->
<!--      <div class="menu__item">-->
<!--        Проверка слейва-->
<!--      </div>-->
      <div class="menu__item" @click="goToRoute('/hand-dozation')">
        Ручная дозация
      </div>
      <div class="menu__item" @click="goToRoute('/monitoring-system')">
        Мониторинг систем
      </div>
      <div class="menu__item" @click="goToRoute('/info-slave')">
        Проверка слейва
      </div>
      <div class="menu__item" @click="goToRoute('/auto-test-lds')">
        Автотест LDS
      </div>
      <div class="menu__item" @click="goToRoute('/errors-lds')">
        Ошибки LDS
      </div>
    </div>
 </div>
</template>

<script>
import BaseHeader from "@/components/common/BaseHeader";
export default {
name: "MenuBase",
  components: {BaseHeader},
  methods: {
    goToRoute(path) {
      this.$router.push(path);
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &__items {
    padding: 20px 0 20px 0;
    color: var(--txt, #292B32);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    .menu__item {
      border-bottom: 1px solid #D0D7E8;
      padding: 20px 0 20px 0;
    }
    .menu__item:nth-last-child(1){
      border-bottom: none;
    }
  }

}
</style>