<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}
.wrapper {
  padding: 20px;
}
.vs__dropdown-toggle {
  border: 2px solid #D0D7E8 !important;
  height: 58px !important;
}
.vs__search {
  font-size: 13px !important;
}
.vs__search:focus {
  font-size: 13px !important;
}
</style>
