<template>
  <div>
    <base-header back title="Мониторинг систем"></base-header>
    <get-monitoring-system></get-monitoring-system>
  </div>

</template>

<script>
import BaseHeader from "@/components/common/BaseHeader";
import GetMonitoringSystem from "@/components/getMonitoringSystem";
export default {
name: "MonitoringSystem",
  components: {GetMonitoringSystem, BaseHeader}
}
</script>

<style scoped>

</style>